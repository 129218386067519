<template>
  <el-card class="page-card">
    <sui-list
      ref="list"
      :column="tableColumn"
      :fun="tableFun"
      :req="tableFunReq"
      :refactor="tableDataRefactor"
      @backflow="backflow"
      @detail="detail"
    />
  </el-card>
</template>
<script>
import SuiList from "@/components/s-ui/list";
import { GetImChatGroup } from "@/utils/api";
import { navto } from "@/utils";

export default {
  components: { SuiList },
  data() {
    return {
      tableColumn: [
        // 咨询者id
        {
          name: "visitorId",
          label: "content.chat.visitorId",
        },
        // 手机号码
        {
          name: "mobile",
          label: "content.chat.mobile",
        },
        // 邮箱
        {
          name: "email",
          label: "content.chat.email",
        },
      ],
      tableFun: GetImChatGroup,
      tableFunReq: {
        orderKey: "",
      },
      tableDataRefactor: (list) => {
        // console.log("list", list);
        return list.map((v) => {
          return {
            ...v,
            mobile: v.visitor.mobile,
            email: v.visitor.email,
          };
        });
      },
      count: 0,
    };
  },
  methods: {
    backflow(count) {
      this.count = count;
    },
    detail(item) {
      console.log("navtodetail:", item);
      navto("/inquiry/inquiry_view", { vid: item.visitorId,email:item.email });
    },
  },
};
</script>

<style lang="scss">
.hd {
  &-row {
    color: #333;
    font-weight: normal;
  }
}
.bd {
  &-row {
    text-align: center;
  }
}
page-card {
  .el-icon-upload {
    padding-top: 30px;
  }
}
</style>